<template>
  <div>
    <CRow>      
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          caption="Lista de Proyectos Manzana"
          icon="fas fa-map-marked-alt"        
          btn_name="proyecto manzana"
          @show_modal="mtd_show_modal"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import { mapActions } from "vuex";

export default {
  components: { CTableWrapper },
  data() {
    return {
      fields:[
        "Id","Proyecto","Manzana_Id" // resource
      ],
      data:[]
    }
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get","post"]),
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
    mtd_getdata: function(){
      this.get({
        url: this.$store.getters.get__url + "/projectstreetblock",
        token: this.$store.getters.get__token
      })
        .then((response) => {       
          this.data = response.data;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show_modal: function(){
      alert('click')
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-search{
  color: #fff;
      background-color: #2819ae;
    border-color: #2517a3;
}
</style>